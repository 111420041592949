.ippon-ryu-font {
  font-family: Amienne;
}

h1.ippon-ryu-font {
  font-weight: normal;
  font-size: 4.0rem;
  margin-bottom: 0px;
  margin-top: 0px;
}

h2.ippon-ryu-font {
  font-weight: normal;
  font-size: 2.0rem;
  margin-bottom: -5px;
  margin-top: 5px;
}

.ant-layout-footer {
  background: #31373e;
}