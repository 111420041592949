.locations-image-container {
    text-align: center;
}

.locations-image {
    width: 300px;
    padding: 10px 0px 0px 0px;
}

.locations-card .ant-card-head  {
    background-color: #31373e;
}

.locations-card .ant-card-head-title {
    white-space: unset;
}

.locations-card h2 {
    color: white;
}

.locations-card h4 {
    color: white;
}
