@import '../../fonts/amienne.css';

.navbar-logo {
    padding-right: 6px;
    height: 48px;
    background: rgba(255, 255, 255, 0.2);
    vertical-align: middle;
}

.navbar-brand-text {
    font-family: Amienne;
    font-size: xx-large;
    float: left;
    background: rgba(255, 255, 255);
    color: black;
}

.navigation-drawer .ant-drawer-header {
    border-bottom: 0px;
}

.navigation-drawer .ant-menu {
    border-right: 0px;
}

.navigation-bars-button .anticon {
    margin-left: 4px;
    font-size: 20px;
}