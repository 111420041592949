.site-footer-container h3 {
    font-family: Amienne;
    font-size:32pt;
    color: white;
    margin-bottom: 0px;
    font-weight: normal;
}

.site-footer-container li {
    color: white;
}

.site-footer-container a {
    color: paleturquoise;
}